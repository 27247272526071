<template>
  <HeaderView />

  <main>
    <div class="main-content main-content-travel">
      <section class="main-travel-banner-area">
        <div class="container">
          <div class="main-banner-title">
            <h2>
              Travel with peace of mind with <br> MECompare Travel Insurance
            </h2>
          </div>
          <div class="main-banner-container">
            <div class="banner-form-area">
              <h4>Get travel quote</h4>

              <div class="form-fields-main">
                <span class="p-input-icon-left">
                  <label class="input-label">Select your phone</label>
                  <vue-tel-input v-model="phone_number" mode="auto" :inputOptions="inputOptions"
                    :dropdownOptions="dropdownOptions" validCharactersOnly @on-input="onInput" @validate="valid"
                    :defaultCountry="default_country"></vue-tel-input>
                </span>
                <span v-if="show_invalid_phone_number_message" style="color:red;">{{ invalid_phone_number_message }}</span>
              </div>

              <button-prime class="start-btn" aria-label="" @click="validate_phone_number" ro>
                <span class="px-3" :loading="start_button_loader">Start</span>
                <img src="../../assets/images/Icons/me.svg">
              </button-prime>

            </div>
          </div>
        </div>
      </section>

      <section class="section-padding step-quote-slider step-quote-slider-medical">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">How to Purchase MECompare Travel Insurance</h2>
            <p class="mb-4">Get started on your worry-free journey with MECompare today!</p>
          </div>

          <div class="width-boxed medical-quote-calculator m-auto">
            <swiper :slides-per-view="3" :space-between="50" :navigation="false" :loop="true" :pagination="pagination"
              :modules="modules" :breakpoints="{
                    '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                    '@0.75': { slidesPerView: 1, spaceBetween: 0 },
                    '@1.00': { slidesPerView: 3, spaceBetween: 0 },
                    '@1.50': { slidesPerView: 3, spaceBetween: 0 },
                  }" @swiper="onSwiper" @slideChange="onSlideChange">
              <swiper-slide>
                <div class="col-12 md:col-12 lg:col-12">
                  <div class="info-box-column">
                    <div class="icon-box-area travel-icon-box-area">
                      <img src="../../assets/images/icon1.svg">
                    </div>
                    <h4 class="info-box-title">Upload your details</h4>
                    <p class="info-box-description">Provide your travel information to get started.</p>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 md:col-12 lg:col-12">
                  <div class="info-box-column">
                    <div class="icon-box-area travel-icon-box-area">
                      <img src="../../assets/images/icon2.svg">
                    </div>
                    <h4 class="info-box-title">Compare Quotes</h4>
                    <p class="info-box-description">Receive quotes and compare different plans to find the best fit.
                    </p>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="col-12 md:col-12 lg:col-12">
                  <div class="info-box-column">
                    <div class="icon-box-area travel-icon-box-area">
                      <img src="../../assets/images/icon3.svg">
                    </div>
                    <h4 class="info-box-title">Buy the Plan</h4>
                    <p class="info-box-description">Select and purchase the plan that suits your needs.</p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>

      <section class="section-padding mobile-padding-bottom-0 compare-insurance-medical">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">What Our Travel Insurance Includes</h2>
            <p class="mb-6">Choose ME Compare Travel Insurance for a worry-free travel experience. Wherever you go,
              we've got you
              covered!
            </p>
          </div>

          <div class="tabs-section-area tabs-section-area-travel width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-12 lg:col-12 p-0">
                <div class="grid w-full m-0">

                  <div class="col-12 md:col-6 lg:col-6 p-0">
                    <div class="col-12 md:col-12 lg:col-12 loss-organs service-box-one travel-includes-one">
                      <div class="info-box-column info-box-column-travel">
                        <div class="service-box-travel">
                          <h4 class="info-box-title">Trip Cancellation</h4>
                          <p class="info-box-description">Reimbursement for trip expenses in case of cancellations due to
                            covered reasons.</p>
                        </div>

                        <div class="service-box-travel">
                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/service1.png">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 md:col-12 lg:col-12 service-box-three travel-includes-three">
                      <div class="info-box-column info-box-column-travel">
                        <div class="service-box-travel">
                          <h4 class="info-box-title">Baggage Coverage</h4>
                          <p class="info-box-description">Protection against loss, theft, or damage to your luggage and
                            personal items.</p>
                        </div>

                        <div class="service-box-travel">
                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/service3.png">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 md:col-12 lg:col-12 service-box-five travel-includes-five">
                      <div class="info-box-column info-box-column-travel">
                        <div class="service-box-travel">
                          <h4 class="info-box-title">Legal Expenses</h4>
                          <p class="info-box-description">Coverage for legal fees you might incur while being abroad.
                          </p>
                        </div>

                        <div class="service-box-travel">
                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/service5.png">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 md:col-12 lg:col-12 service-box-seven travel-includes-seven">
                      <div class="info-box-column info-box-column-travel">
                        <div class="service-box-travel">
                          <h4 class="info-box-title">24/7 Assistance</h4>
                          <p class="info-box-description">Access to a dedicated support team to assist you in
                            emergencies.
                          </p>
                        </div>

                        <div class="service-box-travel">
                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/service7.png">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 md:col-6 lg:col-6 p-0">
                    <div class="col-12 md:col-12 lg:col-12 child-birth service-box-two travel-includes-two">
                      <div class="info-box-column info-box-column-travel">
                        <div class="service-box-travel">
                          <h4 class="info-box-title">Emergency Medical</h4>
                          <p class="info-box-description">Coverage for medical expenses, including hospital stays,
                            surgeries, and prescriptions.</p>
                        </div>

                        <div class="service-box-travel">
                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/service2.png">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 md:col-12 lg:col-12 service-box-four travel-includes-four">
                      <div class="info-box-column info-box-column-travel">
                        <div class="service-box-travel">
                          <h4 class="info-box-title">Travel Delay</h4>
                          <p class="info-box-description">Compensation for additional expenses caused by travel delays.
                          </p>
                        </div>

                        <div class="service-box-travel">
                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/service4.png">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 md:col-12 lg:col-12 service-box-six travel-includes-six">
                      <div class="info-box-column info-box-column-travel">
                        <div class="service-box-travel">
                          <h4 class="info-box-title">Personal Liability</h4>
                          <p class="info-box-description">Coverage for expenses and damages in case of third-party
                            injury
                            or
                            property damage.</p>
                        </div>

                        <div class="service-box-travel">
                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/service6.png">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 md:col-12 lg:col-12 service-box-eight travel-includes-eight">
                      <div class="info-box-column info-box-column-travel">
                        <div class="service-box-travel">
                          <h4 class="info-box-title">Loss of Passport</h4>
                          <p class="info-box-description">Financial assistance for the replacement of a lost passport.
                          </p>
                        </div>

                        <div class="service-box-travel">
                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/service8.png">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding travel-priority-section">
        <div class="container">
          <div class="tabs-section-area width-boxed">
            <h2>ME Compare Travel Insurance</h2>
            <p>At MECompare, we understand that your travel adventures should be filled with excitement, not
              worry. Our travel insurance plans are tailored to provide you with comprehensive coverage and peace of
              mind during your journeys. Whether you're planning a leisurely vacation or a business trip, Mango has got
              you covered.</p>
            <button-prime label="Get Insured!" class="get-insured-btn" />
          </div>
        </div>
      </section>

      <section class="section-padding medical-quote-calculator why-choose-inner-area-travel-section">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">Why Choose MECompare?</h2>
          </div>

          <div class="why-choose-inner-area why-choose-inner-area-travel width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-4 lg:col-4">
                <div class="info-box-column">
                  <div class="icon-box-area travel-icon-box-area">
                    <img src="../../assets/images/insurance-policy.svg">
                  </div>
                  <h4 class="info-box-title">Comprehensive Coverage</h4>
                  <p class="info-box-description">Provides extensive coverage for a wide range of travel-related
                    incidents, ensuring you're protected wherever you go.</p>
                </div>
              </div>

              <div class="col-12 md:col-4 lg:col-4">
                <div class="info-box-column">
                  <div class="icon-box-area travel-icon-box-area">
                    <img src="../../assets/images/files.svg">
                  </div>
                  <h4 class="info-box-title">Easy Claim Process</h4>
                  <p class="info-box-description">Our straightforward claim process ensures that you receive assistance
                    promptly when you need it the most.
                  </p>
                </div>
              </div>

              <div class="col-12 md:col-4 lg:col-4">
                <div class="info-box-column">
                  <div class="icon-box-area travel-icon-box-area">
                    <img src="../../assets/images/mind-mapping.svg">
                  </div>
                  <h4 class="info-box-title">Peace of Mind</h4>
                  <p class="info-box-description">Travel with peace of mind, knowing that Mango has your back in
                    unexpected situations like loss of passport.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- <section class="section-padding insure-now-pay">
        <div class="container">
          <div class="grid width-boxed">
            <div class="col-12 md:col-6 lg:col-6 tabby-plan-box-right">
              <h2 class="inner-heading-title inner-heading-title-tabby">Insure Now Pay Later <br> With <img
                  src="../../assets/images/tabby-icon.svg">
              </h2>
              <p class="inner-para-text">Secure your coverage with MECompare and enjoy the convenience of Tabby's
                flexible payment options. Choose from:</p>

              <div class="grid w-full">
                <div class="col-12 md:col-6 lg:col-6 relative tabby-plan-box-pl">
                  <div class="section-gradient section-gradient-plan-box">
                    <h4 class="info-box-title">2 Month Plan</h4>
                    <p class="info-box-description">Play in 2 months at your flexibility</p>
                  </div>
                </div>

                <div class="col-12 md:col-6 lg:col-6 relative tabby-plan-box-pr">
                  <div class="section-gradient section-gradient-plan-box">
                    <h4 class="info-box-title">4 Month Plan</h4>
                    <p class="info-box-description">Play in 4 months at your flexibility</p>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-12 md:col-6 lg:col-6 relative">
              <div class="inner-section-image">
                <img src="../../assets/images/tabby-mobile.png">
              </div>
            </div>
          </div>
        </div>
      </section> -->

      <section class="section-padding our-loyal-customer">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">Keeping the trust of our loyal <br> customer</h2>
          </div>

          <div class="why-choose-inner-area width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">Exceptional service! MECompare made getting my car insurance a
                    breeze. Their online platform was easy to use.</p>
                  <h4 class="info-box-title">Sara R.</h4>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">Choosing MECompare was the best decision I made for my car
                    insurance. The claims process was smooth.
                  </p>
                  <h4 class="info-box-title">John K</h4>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">I've been a MECompare customer for two years now, and their
                    service has been consistently excellent. </p>
                  <h4 class="info-box-title">Maleha Khan.</h4>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">MECompare offers more than just coverage; they offer peace of
                    mind. The claims team was responsive and efficient.</p>
                  <h4 class="info-box-title">Mona Sameer</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding faq-accordion-section">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3 flex align-items-center justify-content-center">FAQ</h2>
          </div>

          <div class="faq-accordion-card width-boxed">
            <accordion-prime :activeIndex="0">
              <accordionTab-prime header="How do I get a quote for insurance products offered by MECompare Insurance?">
                <p class="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo
                  consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="What sets MECompare Insurance apart from other insurance providers?">
                <p class="m-0">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Nemo enim
                  ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
                  eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius
                  modi.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="Can I customize my insurance coverage with MECompare Insurance?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="How can I file a claim with MECompare Insurance?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
              <accordionTab-prime
                header="Does MECompare Insurance offer any additional services or benefits beyond basic coverage?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
            </accordion-prime>
          </div>
        </div>
      </section>
    </div>
  </main>

  <FooterView />
  <!-- OTP Modal Html Start -->
  <dialog-prime class="otp-modal" v-model:visible="otp_modal" modal header="Otp" :style="{ width: '36rem' }">

    <div class="modal-content-otp">
      <h3>Enter your OTP</h3>
      <span class="p-text-secondary block mb-5">To proceed with your request, please enter the OTP (One-Time Password)
        sent to your registered mobile number.</span>
      <InputOtp-prime v-model="otp_code" :length="6" />

      <div class="flex justify-content-center gap-2 mt-5">
        <button-prime type="button" label="Resend OTP" severity="secondary" :loading="start_button_loader"
          @click="send_otp('resend')" class="resend-otp-btn"></button-prime>
        <button-prime type="button" label="Submit" :loading="otp_submit_button_loader" class="submit-otp-btn"
          @click="verify_otp"></button-prime>
      </div>

      <!-- <div class="flex justify-content-center gap-2 mb-5">
        <button-prime type="button" label="Cancel" severity="secondary" class="cancel-otp-popupbtn"
          @click="otp_modal = false"></button-prime>
      </div> -->

      <span class="p-text-secondary block">The OTP is valid for a limited time. Ensure you enter it promptly to
        avoid
        expiration. If you haven't received the OTP within a few minutes, you can click "Resend OTP" to receive a new
        one.</span>
    </div>
  </dialog-prime>
  <!-- / OTP Modal Html End -->
  <toast-prime />

</template>

<script>
import axios from 'axios'
import HeaderView from '../Header/HeaderView.vue'
import FooterView from '../Footer/FooterView.vue'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
  components: {
    HeaderView,
    FooterView,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
        start_button_loader : false,
        otp_submit_button_loader : false,
        otp_modal : false,
        otp_code : null,
        finishMounted: false,
        default_country : 'AE',
        phone_number : localStorage.getItem("mebrokers.customer.phone.number") == null ? null : localStorage.getItem("mebrokers.customer.phone.number"),
        is_phone_number_invalid : false,
        invalid_phone_number_message : 'Invalid Phone Number',
        show_invalid_phone_number_message : false,
        modules: [Navigation, Pagination, Autoplay, Scrollbar, A11y],
        pagination: {
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        },
        inputOptions : { 
          // dynamicPlaceholder : true,
          showDialCode : true,
          placeholder : 'Enter Phone Number'
          
        },
        dropdownOptions : {
          showDialCodeInList : true,
          showFlags : true,
          showSearchBox : true,
          
        }
    }

  },
  mounted() {
    localStorage.removeItem('mebrokers.customer.travel.journey');
    localStorage.removeItem('mebrokers.customer.travel.plans.comparison');
  },
  methods: {
    send_otp : function(type) {
          this.start_button_loader = true;
          axios.get(this.api_url+'sms/send_otp', {
          params : {
              mobile : this.phone_number.replaceAll(' ', '')
          }
          }).then((response) => {
              if (response.data.data == true) {
                this.start_button_loader = false;
                this.otp_modal = true;
                if (type == 'resend') {
                  this.$toast.add({ severity: 'success', detail: 'Otp sent successfully!', life: 3000 });
                }
              }
          });
      },
      verify_otp : function() {
          if (this.otp_code == null || this.otp_code == '' || this.otp_code.length < 6) {
            this.$toast.add({ severity: 'error', detail: 'otp required!', life: 3000 });
            return false;
          }

          this.otp_submit_button_loader = true;
          axios.get(this.api_url+'sms/verify_otp', {
          params : {
              mobile : this.phone_number.replaceAll(' ', ''),
              otp_code : this.otp_code
          }
          }).then((response) => {
              if (response.data.data == true) {
                this.otp_submit_button_loader = false;
                this.otp_modal = false;
                this.$toast.add({ severity: 'success', detail: 'Otp verified!', life: 3000 });
                localStorage.setItem("mebrokers.customer.phone.number", this.phone_number.replaceAll(' ', ''));
                localStorage.setItem("mebrokers.customer.phone.number.verified", JSON.stringify({mobile:this.phone_number.replaceAll(' ', ''), verified:'yes'}));
                localStorage.removeItem('mebrokers.customer.travel.journey');
                localStorage.removeItem('mebrokers.customer.travel.plans.comparison');
                this.$router.push({
                    name : 'travel-journey',
                });
              } else {
                this.$toast.add({ severity: 'error', detail: 'Otp not verified!', life: 3000 });
                this.otp_submit_button_loader = false;
              }
          });
      },
      onInput : function(phone, obj) {
        if(obj.valid) {
          this.is_phone_number_invalid = false;
        } else {
          this.is_phone_number_invalid = true;
        }
      },
      validate_phone_number : function() {
        if(!this.is_phone_number_invalid) {
          this.show_invalid_phone_number_message = false;
          
          localStorage.setItem("mebrokers.customer.phone.number", this.phone_number.replaceAll(' ', ''));
          if (localStorage.getItem("mebrokers.customer.phone.number.verified") != null && localStorage.getItem("mebrokers.customer.phone.number") != null) {
            var parse_data = JSON.parse(localStorage.getItem("mebrokers.customer.phone.number.verified"));
            if ((parse_data.mobile == localStorage.getItem("mebrokers.customer.phone.number")) && parse_data.verified == 'yes') {
                localStorage.removeItem('mebrokers.customer.travel.journey');
                localStorage.removeItem('mebrokers.customer.travel.plans.comparison');
                this.$router.push({
                    name : 'travel-journey',
                });
            } else {
              this.send_otp('first_send');
            }
          } else {
            this.send_otp('first_send');
          }
        } else {
          this.show_invalid_phone_number_message = true;
          return false;
        }
      },
      valid : function(obj) {
        if (obj.valid != undefined) {
          if(obj.valid) {
            this.show_invalid_phone_number_message = false;
          }
        }
      },

  }
}
</script>

<style></style>