<template>
  <HeaderView />

  <main>
    <div class="main-content main-content-medical">
      <section class="main-medical-banner-area">
        <div class="container">
          <div class="main-banner-title">
            <h2>
              Me Compare Medical Insurance Coverage
            </h2>
          </div>
          <div class="main-banner-container">
            <div class="banner-form-area">
              <h4>Get health quote</h4>

              <div class="form-fields-main">
                <span class="p-input-icon-left">
                  <label class="input-label">Select your phone</label>
                  <vue-tel-input v-model="phone_number" mode="auto" :inputOptions="inputOptions"
                    :dropdownOptions="dropdownOptions" validCharactersOnly @on-input="onInput" @validate="valid"
                    :defaultCountry="default_country"></vue-tel-input>
                </span>
                <span v-if="show_invalid_phone_number_message" style="color:red;">{{ invalid_phone_number_message }}</span>
              </div>

              <button-prime class="start-btn" aria-label="" @click="validate_phone_number" ro>
                <span class="px-3" :loading="start_button_loader">Start</span>
                <img src="../../assets/images/Icons/me.svg">
              </button-prime>

            </div>
          </div>
        </div>
      </section>

      <section class="section-padding step-quote-slider step-quote-slider-medical">
        <div class="container">
          <div class="section-title-heading">
            <h2>Get insured in easy 2-Step </h2>
          </div>

          <div class="width-boxed m-auto">
            <swiper :slides-per-view="1" :space-between="10" setWrapperSize="ture" :navigation="true" :loop="true"
              :pagination="pagination" :modules="modules" :breakpoints="{
                    '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                    '@0.75': { slidesPerView: 1, spaceBetween: 0 },
                    '@1.00': { slidesPerView: 1, spaceBetween: 0 },
                    '@1.50': { slidesPerView: 1, spaceBetween: 0 },
              }" @swiper="onSwiper" @slideChange="onSlideChange">
              <swiper-slide>
                <div class="grid ml-0 mr-0 swiper-slide-column">
                  <div class="col-12 md:col-6 lg:col-6 quote-slider-info-box">
                    <div class="step-quote-swiper-content">
                      <div class="slider-quote-icon-box">
                        <img src="../../assets/images/Icons/slider-quote-medicalCloud.svg">
                      </div>

                      <h2>Upload your details</h2>
                      <p>Provide basic details for a personalized insurance quote tailored to your needs.</p>
                      <button-prime label="Get Quote!" class="get-quote-btn-slider" />
                    </div>
                  </div>

                  <div class="col-12 md:col-6 lg:col-6 quote-slider-image-box">
                    <div class="quote-slider-image">
                      <img src="../../assets/images/2.png">
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="grid ml-0 mr-0 swiper-slide-column">
                  <div class="col-12 md:col-6 lg:col-6 quote-slider-info-box">
                    <div class="step-quote-swiper-content">
                      <div class="slider-quote-icon-box">
                        <img src="../../assets/images/Icons/medicalDoller.svg">
                      </div>

                      <h2>Secure Your Coverage</h2>
                      <p>Complete the online application to secure your insurance hassle-free.</p>
                      <button-prime label="Get Quote!" class="get-quote-btn-slider" />
                    </div>
                  </div>

                  <div class="col-12 md:col-6 lg:col-6 quote-slider-image-box">
                    <div class="quote-slider-image">
                      <img src="../../assets/images/6.png">
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>

      <section class="section-padding mobile-padding-bottom-0 compare-insurance-medical">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">Coverage with Me Compare Insurance </h2>
            <p>Find out the cost of your medical insurance online in a couple of minutes.</p>
          </div>

          <div class="tabs-section-area width-boxed">
            <tabView-prime>
              <tabPanel-prime header="Premium">
                <div class="grid w-full">
                  <div class="col-12 md:col-6 lg:col-6 p-0">
                    <div class="grid w-full m-0 gap-1">
                      <div class="col-12 md:col-12 lg:col-12 emergency-care">
                        <div class="info-box-column">
                          <h4 class="info-box-title">Emergency Care</h4>
                          <p class="info-box-description">Get instant quotes and compare policies effortlessly.</p>

                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/Icons/emergencycare.png">
                          </div>
                        </div>
                      </div>

                      <div class="col-12 md:col-12 lg:col-12 loss-organs">
                        <div class="info-box-column">
                          <h4 class="info-box-title">Loss of Organs</h4>
                          <p class="info-box-description">Get instant quotes and compare policies effortlessly.</p>

                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/Icons/lossOrgans.png">
                          </div>
                        </div>
                      </div>

                      <div class="col-12 md:col-12 lg:col-12 child-birth">
                        <div class="info-box-column">
                          <h4 class="info-box-title">Chid Birth</h4>
                          <p class="info-box-description">Get instant quotes and compare policies effortlessly.</p>

                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/Icons/childBirth.png">
                          </div>
                        </div>
                      </div>

                      <div class="col-12 md:col-12 lg:col-12 specialist-consultations">
                        <div class="info-box-column">
                          <h4 class="info-box-title">Specialist Consultations</h4>
                          <p class="info-box-description">Get instant quotes and compare policies effortlessly.</p>

                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/Icons/specialistconsultations.png">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 md:col-6 lg:col-6 p-0">
                    <div class="grid w-full m-0 gap-1">
                      <div class="col-12 md:col-12 lg:col-12 surgical-procedures">
                        <div class="info-box-column">
                          <h4 class="info-box-title">Surgical Procedures</h4>
                          <p class="info-box-description">Get instant quotes and compare policies effortlessly.
                          </p>

                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/Icons/SurgicalProcedur.png">
                          </div>
                        </div>
                      </div>

                      <div class="col-12 md:col-12 lg:col-12 hospitalization-expenses">
                        <div class="info-box-column">
                          <h4 class="info-box-title">Hospitalization Expenses</h4>
                          <p class="info-box-description">Get instant quotes and compare policies effortlessly.</p>

                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/Icons/hospitalizationExpenses.png">
                          </div>
                        </div>
                      </div>

                      <div class="col-12 md:col-12 lg:col-12 prescription-medications">
                        <div class="info-box-column">
                          <h4 class="info-box-title">Prescription Medications</h4>
                          <p class="info-box-description">Protection for damages caused by fires.</p>

                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/Icons/prescriptionmedications.png">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tabPanel-prime>
              <tabPanel-prime header="Basic">
                <div class="grid w-full">
                  <div class="col-12 md:col-6 lg:col-6 p-0">
                    <div class="grid w-full m-0 gap-1">
                      <div class="col-12 md:col-12 lg:col-12 emergency-care">
                        <div class="info-box-column">
                          <h4 class="info-box-title">Emergency Care</h4>
                          <p class="info-box-description">Get instant quotes and compare policies effortlessly.</p>

                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/Icons/emergencycare.png">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 md:col-6 lg:col-6 p-0">
                    <div class="grid w-full m-0 gap-1">
                      <div class="col-12 md:col-12 lg:col-12 specialist-consultations">
                        <div class="info-box-column">
                          <h4 class="info-box-title">Outpatient Care</h4>
                          <p class="info-box-description">Get instant quotes and compare policies effortlessly.</p>

                          <div class="tabs-panels-img-box">
                            <img src="../../assets/images/Icons/specialistconsultations.png">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tabPanel-prime>
            </tabView-prime>
          </div>
        </div>
      </section>

      <section class="section-padding priority-section">
        <div class="container">
          <div class="tabs-section-area width-boxed">
            <h2>Your Health, Our Priority</h2>
            <p>At MECompare, we believe that everyone deserves access to quality healthcare. Let us be your
              partner
              in protecting your health and well-being. Choose Mango Medical Insurance and experience the assurance of
              reliable coverage.</p>
            <button-prime label="Get Insured!" class="get-insured-btn" />
          </div>
        </div>
      </section>

      <section class="section-padding medical-quote-calculator">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">Medical Quote Calculator</h2>
            <p>Find out the cost of your medical insurance online in a couple of minutes
            </p>
          </div>

          <div class="why-choose-inner-area width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-3 lg:col-3">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/users.svg">
                  </div>
                  <h4 class="info-box-title">User Details</h4>
                  <p class="info-box-description">Provide user essential information such as age, medical history, and
                    coverage preferences.</p>
                </div>
              </div>

              <div class="col-12 md:col-3 lg:col-3">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/files.svg">
                  </div>
                  <h4 class="info-box-title">Quote Generation</h4>
                  <p class="info-box-description">Receive your personalized medical insurance quote instantly.
                  </p>
                </div>
              </div>

              <div class="col-12 md:col-3 lg:col-3">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/settings-medical.svg">
                  </div>
                  <h4 class="info-box-title">Customization Options</h4>
                  <p class="info-box-description">Explore different coverage levels and customize your plan based on
                    your specific needs.</p>
                </div>
              </div>

              <div class="col-12 md:col-3 lg:col-3">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/medical-assistance.svg">
                  </div>
                  <h4 class="info-box-title">Live Assistance</h4>
                  <p class="info-box-description">Personalized assistance to address any queries you may have about the
                    generated quote.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- <section class="section-padding insure-now-pay">
        <div class="container">
          <div class="grid width-boxed">
            <div class="col-12 md:col-6 lg:col-6 tabby-plan-box-right">
              <h2 class="inner-heading-title inner-heading-title-tabby">Insure Now Pay Later <br> With <img
                  src="../../assets/images/tabby-icon.svg">
              </h2>
              <p class="inner-para-text">Secure your coverage with MECompare and enjoy the convenience of Tabby's
                flexible payment options. Choose from:</p>

              <div class="grid w-full">
                <div class="col-12 md:col-6 lg:col-6 relative tabby-plan-box-pl">
                  <div class="section-gradient section-gradient-plan-box">
                    <h4 class="info-box-title">2 Month Plan</h4>
                    <p class="info-box-description">Play in 2 months at your flexibility</p>
                  </div>
                </div>

                <div class="col-12 md:col-6 lg:col-6 relative tabby-plan-box-pr">
                  <div class="section-gradient section-gradient-plan-box">
                    <h4 class="info-box-title">4 Month Plan</h4>
                    <p class="info-box-description">Play in 4 months at your flexibility</p>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-12 md:col-6 lg:col-6 relative">
              <div class="inner-section-image">
                <img src="../../assets/images/tabby-mobile.png">
              </div>
            </div>
          </div>
        </div>
      </section> -->

      <section class="section-padding our-loyal-customer">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">Keeping the trust of our loyal <br> customer</h2>
          </div>

          <div class="why-choose-inner-area width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">Exceptional service! MECompare made getting my car insurance a
                    breeze. Their online platform was easy to use.</p>
                  <h4 class="info-box-title">Sara R.</h4>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">Choosing MECompare was the best decision I made for my car
                    insurance. The claims process was smooth.
                  </p>
                  <h4 class="info-box-title">John K</h4>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">I've been a MECompare customer for two years now, and their
                    service has been consistently excellent. </p>
                  <h4 class="info-box-title">Maleha Khan.</h4>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/Icons/user-testimonial.svg">
                  </div>

                  <p class="info-box-description">MECompare offers more than just coverage; they offer peace of
                    mind. The claims team was responsive and efficient.</p>
                  <h4 class="info-box-title">Mona Sameer</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding faq-accordion-section">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3 flex align-items-center justify-content-center">FAQ</h2>
          </div>

          <div class="faq-accordion-card width-boxed">
            <accordion-prime :activeIndex="0">
              <accordionTab-prime header="How do I get a quote for insurance products offered by MECompare Insurance?">
                <p class="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo
                  consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="What sets MECompare Insurance apart from other insurance providers?">
                <p class="m-0">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Nemo enim
                  ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
                  eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius
                  modi.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="Can I customize my insurance coverage with MECompare Insurance?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="How can I file a claim with MECompare Insurance?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
              <accordionTab-prime
                header="Does MECompare Insurance offer any additional services or benefits beyond basic coverage?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
            </accordion-prime>
          </div>
        </div>
      </section>
    </div>
  </main>

  <FooterView />
  <dialog-prime class="otp-modal" v-model:visible="otp_modal" modal header="Otp" :style="{ width: '36rem' }">

<div class="modal-content-otp">
  <h3>Enter your OTP</h3>
  <span class="p-text-secondary block mb-5">To proceed with your request, please enter the OTP (One-Time Password)
    sent to your registered mobile number.</span>
  <InputOtp-prime v-model="otp_code" :length="6" />

  <div class="flex justify-content-center gap-2 mt-5">
    <button-prime type="button" label="Resend OTP" severity="secondary" :loading="start_button_loader"
      @click="send_otp('resend')" class="resend-otp-btn"></button-prime>
    <button-prime type="button" label="Submit" :loading="otp_submit_button_loader" class="submit-otp-btn"
      @click="verify_otp"></button-prime>
  </div>

  <div class="flex justify-content-center gap-2 mb-5">
    <button-prime type="button" label="Cancel" severity="secondary" class="cancel-otp-popupbtn"
      @click="otp_modal = false"></button-prime>
  </div>

  <span class="p-text-secondary block">The OTP is valid for a limited time. Ensure you enter it promptly to
    avoid
    expiration. If you haven't received the OTP within a few minutes, you can click "Resend OTP" to receive a new
    one.</span>
</div>
</dialog-prime>
  <toast-prime />
</template>

<script>
import axios from 'axios'
import HeaderView from '../Header/HeaderView.vue'
import FooterView from '../Footer/FooterView.vue'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    components: {
      HeaderView,
      FooterView,
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        start_button_loader : false,
        otp_submit_button_loader : false,
        otp_modal : false,
        otp_code : null,
        finishMounted: false,
        default_country : 'AE',
        phone_number : localStorage.getItem("mebrokers.customer.phone.number") == null ? null : localStorage.getItem("mebrokers.customer.phone.number"),
        is_phone_number_invalid : false,
        invalid_phone_number_message : 'Invalid Phone Number',
        show_invalid_phone_number_message : false,
        modules: [Navigation, Pagination, Autoplay, Scrollbar, A11y],
        pagination: {
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        },
        inputOptions : { 
          // dynamicPlaceholder : true,
          showDialCode : true,
          placeholder : 'Enter Phone Number'
          
        },
        dropdownOptions : {
          showDialCodeInList : true,
          showFlags : true,
          showSearchBox : true,
          
        }
      }
        
    },
    mounted() {
      localStorage.removeItem('mebrokers.customer.medical.journey');
      localStorage.removeItem('mebrokers.customer.medical.plans.comparison');
    },
    methods : {
      send_otp : function(type) {
          this.start_button_loader = true;
          axios.get(this.api_url+'sms/send_otp', {
          params : {
              mobile : this.phone_number.replaceAll(' ', '')
          }
          }).then((response) => {
              if (response.data.data == true) {
                this.start_button_loader = false;
                this.otp_modal = true;
                if (type == 'resend') {
                  this.$toast.add({ severity: 'success', detail: 'Otp sent successfully!', life: 3000 });
                }
              }
          });
      },
      verify_otp : function() {
          if (this.otp_code == null || this.otp_code == '' || this.otp_code.length < 6) {
            this.$toast.add({ severity: 'error', detail: 'otp required!', life: 3000 });
            return false;
          }

          this.otp_submit_button_loader = true;
          axios.get(this.api_url+'sms/verify_otp', {
          params : {
              mobile : this.phone_number.replaceAll(' ', ''),
              otp_code : this.otp_code
          }
          }).then((response) => {
              if (response.data.data == true) {
                this.otp_submit_button_loader = false;
                this.otp_modal = false;
                this.$toast.add({ severity: 'success', detail: 'Otp verified!', life: 3000 });
                localStorage.setItem("mebrokers.customer.phone.number", this.phone_number.replaceAll(' ', ''));
                localStorage.setItem("mebrokers.customer.phone.number.verified", JSON.stringify({mobile:this.phone_number.replaceAll(' ', ''), verified:'yes'}));
                localStorage.removeItem('mebrokers.customer.medical.journey');
                localStorage.removeItem('mebrokers.customer.medical.plans.comparison');
                this.$router.push({
                    name : 'medical-journey',
                });
              } else {
                this.$toast.add({ severity: 'error', detail: 'Otp not verified!', life: 3000 });
                this.otp_submit_button_loader = false;
              }
          });
      },
      onInput : function(phone, obj) {
        if(obj.valid) {
          this.is_phone_number_invalid = false;
        } else {
          this.is_phone_number_invalid = true;
        }
      },
      validate_phone_number : function() {
        if(!this.is_phone_number_invalid) {
          this.show_invalid_phone_number_message = false;
          
          localStorage.setItem("mebrokers.customer.phone.number", this.phone_number.replaceAll(' ', ''));
          if (localStorage.getItem("mebrokers.customer.phone.number.verified") != null && localStorage.getItem("mebrokers.customer.phone.number") != null) {
            var parse_data = JSON.parse(localStorage.getItem("mebrokers.customer.phone.number.verified"));
            if ((parse_data.mobile == localStorage.getItem("mebrokers.customer.phone.number")) && parse_data.verified == 'yes') {
                localStorage.removeItem('mebrokers.customer.medical.journey');
                localStorage.removeItem('mebrokers.customer.medical.plans.comparison');
                this.$router.push({
                    name : 'medical-journey',
                });
            } else {
              this.send_otp('first_send');
            }
          } else {
            this.send_otp('first_send');
          }
        } else {
          this.show_invalid_phone_number_message = true;
          return false;
        }
      },
      valid : function(obj) {
        if (obj.valid != undefined) {
          if(obj.valid) {
            this.show_invalid_phone_number_message = false;
          }
        }
      },
    }
}
</script>

<style>

</style>